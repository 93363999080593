<template>
  <div>
    <label class="uppercase font-semibold text-sm">Related Product(s) </label>
    <div class="relative">
      <input
        class="bge-input bge-input-spacing w-full rounded cursor-pointer block pr-8"
        readonly
        v-model="displayValue"
        @click="openContactSearch"
      />
      <!-- <button
        v-if="selection"
        @click="clearContact"
        name="Clear Customer"
        class="m-1 z-10 text-red-500 hover:bg-red-200 cursor-pointer rounded-full absolute inset-y-0 right-0 flex items-center px-2 transition-colors duration-200"
      >
        <svg
                class="stroke-current h-6 w-6"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12" />
              </svg>
      </button> -->
      <div
        v-if="searchIsOpen"
        class="-mt-3 border border-gray-500 z-50 absolute left-0 right-0 bg-white shadow-lg rounded"
      >
        <div class="p-4">
          <input
            ref="searchField"
            class="input-field"
            v-model="searchTerm"
            @keydown="searchContact"
            placeholder="Enter 3 letters or more..."
          />
          <div
            v-if="searchResults.length == 0"
            class="flex justify-center items-center pt-4 pb-1"
          >
            <p>No Products match your search</p>
          </div>
        </div>
        <div
          v-if="searchResults.length > 0"
          class="max-h-64 overflow-y-scroll pb-3"
        >
          <template v-for="result in searchResults">
            <!-- <li
                @click="selectContact(result)"
                :key="result.contact_id"
                class="ml-3 px-4 py-3 hover:bg-orange-200 even:bg-gray-100 cursor-pointer"
              >
                {{ result.name }}
              </li> -->
            <div class="ml-3 py-2" :key="result.contact_id">
              <label class="w-full flex items-center">
                <input
                  class="h-5 w-5 form-checkbox focus:shadow-none text-primary focus:border-primary mr-4"
                  type="checkbox"
                  v-model="result.isSelected"
                  @change="onSelectionChange(result)"
                />
                <span>{{ result.name }}</span>
              </label>
            </div>
          </template>
        </div>
        <button
          @click="closeContactSearch"
          type="button"
          class="bg-red-500 hover:bg-red-600 w-full py-2 text-white transition-colors duration-200"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "SearchField",
  props: {
    selection: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      searchTerm: "",
      searchResults: [],
      searchIsOpen: false,
    };
  },
  computed: {
    displayValue: function() {
      let value = "";
      console.log("SELECTION IS", this.selection);
      this.selection.forEach((item, idx) => {
        if (item.name) {
          value = value + item.name;
        } else {
          value = value + "Product";
        }
        if (idx + 1 != this.selection.length) {
          value = value + ", ";
        }
      });
      return value;
    },
  },
  methods: {
    openContactSearch: function() {
      this.searchIsOpen = true;
      this.$nextTick(() => {
        this.$refs.searchField.focus();
      });
    },
    closeContactSearch: function() {
      this.searchIsOpen = false;
      this.searchTerm = "";
      this.searchResults = [];
    },
    searchContact: _.debounce(async function() {
      try {
        if (this.searchTerm.length >= 3) {
          let results = await this.ProductService.getProducts(this.searchTerm);
          this.searchResults = results;

          let namesOfSelection = [];
          this.selection.forEach((item) => {
            return namesOfSelection.push(item.name);
          });

          this.searchResults.forEach((result) => {
            if (namesOfSelection.includes(result.name)) {
              result.isSelected = true;
            } else {
              result.isSelected = false;
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    }, 500),
    // selectContact: function(contact) {
    //   this.$emit("update:selection", contact.contact_id);
    //   this.displayValue = contact.name;
    //   this.closeContactSearch();
    // },
    // clearContact: function() {
    //   this.$emit("update:selection", "");
    //   this.displayValue = "";
    // },
    onSelectionChange: function(product) {
      console.log(product);
      let tempArray = this.selection;
      let namesOfSelection = [];
      this.selection.forEach((item) => {
        return namesOfSelection.push(item.name);
      });
      if (namesOfSelection.includes(product.name)) {
        let newArray = _.remove(tempArray, (xProd) => {
          if (product.name != xProd.name) {
            return xProd;
          }
        });
        this.$emit("update:selection", newArray);
      } else {
        let newArray = [...tempArray, product];
        this.$emit("update:selection", newArray);
      }
    },
  },
};
</script>
